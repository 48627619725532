import React from 'react';
import '../sass/Title.scss';
import '../sass/MainNet.scss';
import SideBySide from '../components/SideBySide';

const MainNet: React.FC = () => {
  return (
    <h2 className="App-mainNet">
      <SideBySide layout="1fr auto">
        <div>1</div>
        <div>
          <button>Outages</button>
        </div>
      </SideBySide>
      MainNet
    </h2>
  );
};

export default MainNet;
