import React from 'react';
import Title from '../components/Title';
import SideBySide from '../components/SideBySide';
import MainNet from '../components/MainNet';
import TestNet from '../components/TestNet';

const Uptime: React.FC = () => {
  return (
    <div>
      <Title>Uptime</Title>
      <SideBySide>
      <MainNet/>
      <TestNet/>
    </SideBySide>
    </div>
  );
};

export default Uptime;
