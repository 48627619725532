import React from 'react';
import '../sass/Header.scss';

const Header: React.FC = () => {
  return (
    <header className="App-header">
      <div className="header-container">
        <div className="logo">Ayetu</div>
        <nav>
          <button className="refresh-button"></button>
          <button className="sign-in-button">Sign In</button>
        </nav>
      </div>
    </header>
  );
};

export default Header;
