import React from 'react';
import '../sass/TestNet.scss';
import SideBySide from '../components/SideBySide';

const TestNet: React.FC = () => {
  return (
    <h2 className="App-testNet">
      <SideBySide layout="1fr auto">
        <div>1</div>
        <div>
          <button>Outages</button>
        </div>
      </SideBySide>
    </h2>
  );
};

export default TestNet;
