import React, { ReactNode } from 'react';
import '../sass/SideBySide.scss';

interface SideBySideProps {
  children: ReactNode[];
  layout?: string;
}

const SideBySide: React.FC<SideBySideProps> = ({ children, layout = "1fr 1fr" }) => {
  return (
    <div className="side-by-side-container" style={{ gridTemplateColumns: layout }}>
      <div className="left-child">{children[0]}</div>
      <div className="right-child">{children[1]}</div>
    </div>
  );
};

export default SideBySide;
