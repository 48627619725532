import React from 'react';
import '../sass/Title.scss';

interface TitleProps {
  children: React.ReactNode;
}

const Title: React.FC<TitleProps> = ({ children }) => {
  return (
    <h2 className="App-title">
        <div className="App-title--container">
            {children}
        </div>
  
    </h2>
  );
};

export default Title;
